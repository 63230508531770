@import "styles/variables.scss";
.Root {
  height: 100%;
  max-height: 890px;
  overflow: hidden;
  position: relative;
}

.ItemsContainer {
  .Item:first-child {
    border-top: none;
    padding-top: 0;
  }
}

.Item {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border-top: 1px solid $grey-primary-6;
  transition: $transition;

  &:hover {
    cursor: pointer;
    color: $grey-primary
  }
}

.Title {
  font-size: $font-size-s;
  line-height: $line-height-base;
  font-weight: 500;
  margin: 0 0 5px 0;
}

.Comments {
  font-size: $font-size-xs;
  line-height: $line-height-base;
  font-weight: 600;
  margin: 0;
}

.Date {
  font-size: $font-size-xs;
  line-height: $line-height-base;
  color: $grey-primary;
  font-weight: 400;
  margin: 0 8px 0 0;
}
